import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {KGlackinTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import FreeTrialButton from '../components/free-trial-button';

import mobileGpsSrc from '../images/mobile-gps.svg';
import geofenceSrc from '../images/geofence.png';
import mapMultipleSrc from '../images/map-multiple.svg';

const GPSTimeTracking = () => (
  <Layout>
    <SEO 
    	title="GPS Timesheets with Geofence Clock In"
    	description="GPS Clock In with configurable geofences to ensure employees are working where they are meant to be."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            GPS timesheets for
			            <br />
			            <span className="text-highlight-600">travelling employees</span>
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Locate every employee clock in with a GPS location for
			            staff using the TimeKeeper mobile app.
			          </p>
			          <FreeTrialButton />
			          <AppStoreIcons />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={mobileGpsSrc} alt="GPS Map Locations" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     <LeftFeature 
				    	sectionTitle="Geofence Clock Entry Areas" 
				    	title="Configure Geofence Areas" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          TimeKeeper enables you to define geofences for jobs so only an employee within a configurable distance of that location can clock in or out.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          Perfect for ensuring your employees are clocking in and out where they are meant to be.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={geofenceSrc}
				    	imgAlt="Geofence Areas on Clock In"
				     />
				     <RightFeature 
				    	sectionTitle="Live Employee Locations" 
				    	title="Where is each employee at?" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          TimeKeeper utilising the GPS clock in data to provide a live list of where each worker is at right now. 
						        </FeaturePararaph>
						        <FeaturePararaph>
						          Better still you can view this on the TimeKeeper web portal from your computer, mobile or tablet anytime and anywhere 
						          in the world.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={mapMultipleSrc}
				    	imgAlt="Markers of who is in on each building site"
				    />
				</div>
				<KGlackinTestimonial />
				<FreeTrial />
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default GPSTimeTracking
